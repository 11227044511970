import styled from "styled-components"
import { device } from './devices';


const Container = styled.div`
    margin: auto auto;
    maxWidth: 600px;
    padding: 1em;
   margin-top:20px;
    margin-bottom:20px;
`

export {Container}

const ContainerFluid = styled.div`
  
    background: #002833;
    color:white;
    padding-top: 3em;
    padding-bottom: 3em;
    margin-top:20px;
    margin-bottom:20px;

}
`

export {ContainerFluid}

const BgImageOverlay = styled.div`
    margin: auto auto;
   
    padding:1em;
    color:white;
    background-color:RGBA(0, 40, 51, 0.7);
    border-radius: 10px;
    box-shadow: 1px -1px 5px black;
`

export {BgImageOverlay}

const BgImageContainer = styled.div`
    margin: auto auto;
    width: 80%;
    padding-top:15em;
    
    h1 {
    width:100%;
    color:white;
    font-size:calc(1.5rem + 3vw);
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    
    white-space: normal;
    }
    @media (min-width:769px ) {
        h1 {
            white-space: pre;
        }
    }

`

export {BgImageContainer}

const RowContainer = styled.div`
    margin: auto auto;
    maxWidth: 600px;
    padding: 1em;
   margin-top:20px;
    margin-bottom:20px;
    display:flex;
    flex-direction: column;
    justify-content:center;
    gap:10px;
    @media ${device.tablet} {
        flex-direction:row;
        width:100%;
        flex-wrap:wrap;
    }
`

export {RowContainer}

const RowContainerFluid = styled.div`
  
    background: #002833;
    color:white;
    padding-top: 3em;
    padding-bottom: 3em;
    margin-top:20px;
    margin-bottom:20px;
    display:flex;
    flex-direction: column;
    justify-content:center;
    gap:10px;
    @media ${device.tablet} {
        flex-direction:row;
        width:100%;
        flex-wrap: wrap;
    }
}
`

export {RowContainerFluid}