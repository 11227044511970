import * as React from "react"
import { window, document, exists } from 'browser-monads';
import Layout from "../components/layout"
import Seo from "../components/seo"
import {ContainerFluid, Container} from "../styled/container";

const NotFoundPage = () => (
  <Layout>
    <ContainerFluid>
    <div style={{backgroundImage:"url('http://wp.luis-backt-und-kocht.de/wp-content/uploads/55_Reisebus_NOe_cDr.Richard_M.Scheer.jpg')", height:"90vh"}}>
    <div style={{padding:"1em", marginTop:"5vh", paddingLeft:"8em", background:"RGBA(0,0,0,0.9)"}}>
        <h3>404: Der Bus ist dann wohl abgefahren...</h3>
        <p>Diese Seite existiert leider nicht - versuchen Sie es doch auf der <a href="/">Startseite</a></p>
    </div>
    </div>
    </ContainerFluid>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
